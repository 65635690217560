import axios from 'axios';

export const getPayoutBatches = () => {
    return axios.get('soap/batches/');
}

export const getProcessors = () => {
    return axios.get('soap/processors/');
}

export const getCustomerBankAccounts = () => {
    return axios.get('soap/customer_bank_accounts/');
}

export const createTransaction = (processor_transaction_id, processor, customer_bank_account) => {
    return axios.post('soap/transactions/', {processor_transaction_id, processor, customer_bank_account})
}

