<template>
    <li class="list-group-item p-0 d-flex flex-column">
        <div class="d-flex flex-row" :class="{'row-bottom-border': visible}">
            <div class="d-flex flex-fill" style="margin: .5rem 1rem;">
                <span>{{ formatDate(batch.created) }}</span>
                <span class="ml-auto">{{ moneyFormat(batch.amount) }}</span>
            </div>
            <div class="d-flex align-items-center" style="border-left: 1px solid rgba(0,0,0,.125)">
                <div @click="visible = !visible" class="d-flex align-items-center">
                    <div v-show="visible" style="margin: .5rem 1rem; cursor: pointer;">
                        <i class="fas fa-minus-circle" />
                    </div>
                    <div v-show="!visible" style="margin: .5rem 1rem; cursor: pointer;">
                        <i class="fas fa-plus-circle" />
                    </div>
                </div>
            </div>
        </div>
        <div v-show="visible" style="margin: .5rem 1rem; overflow-x: auto;">
            <table class="table table-sm table-borderless">
                <thead>
                <tr>
                    <th>Customer</th>
                    <th>Date</th>
                    <th>Account Last Four</th>
                    <th>Routing Last Four</th>
                    <th>Type</th>
                    <th>Amount</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="payout in batch.payouts" :key="payout.id">
                    <td>{{ payout.customer_bank_account.name }}</td>
                    <td>{{ formatDate(payout.created) }}</td>
                    <td>{{ payout.customer_bank_account.account_number }}</td>
                    <td>{{ payout.customer_bank_account.routing_number }}</td>
                    <td>{{ payout.type }}</td>
                    <td>{{ moneyFormat(payout.amount) }}</td>
                </tr>
                </tbody>
            </table>
        </div>
    </li>
</template>

<script>
    import moment from "moment";
    import moneyFormat from "../../mixins/moneyFormat";

    export default {
        name: 'PayoutTableRows',
        mixins: [
            moneyFormat
        ],
        props: {
            batch: Object
        },
        data() {
            return {
                visible: false,
                searchText: ''
            }
        },
        methods: {
            formatDate(date) {
                return moment(date).format('ll');
            }
        }
    }
</script>

<style scoped>
    .row-bottom-border {
        border-bottom: 1px solid rgba(0,0,0,.125);
    }
</style>
