<template>
    <div class="container mt-4">
        <div class="card">
            <div class="card-header d-flex align-items-center">
                <div>Payout Batches</div>
                <div class="form-group ml-auto mb-0" style="width: 195px">
                    <input type="text"
                           class="form-control form-control-sm my-auto"
                           v-model="searchText"
                           placeholder="Search"/>
                </div>
            </div>
            <div class="card-body">
                <Spinner v-if="loading" size="60" line-fg-color="gray" />
                <span v-else-if="!batches.length || !validBatches.length">No information to display</span>
                <ul v-else class="list-group">
                    <PayoutTableRows v-for="batch in currentPageBatches" :key="batch.id" :batch="batch"/>
                </ul>
                <div class="btn-group btn-group-sm mt-4" role="group" v-if="numberOfPages > 1">
                    <button type="button"
                            :disabled="pageNumber === 1"
                            @click="pageNumber--"
                            class="btn btn-secondary">
                        Prev
                    </button>
                    <button v-for="index in numberOfPages"
                            :key="index"
                            type="button"
                            class="btn btn-secondary"
                            :class="{'activePaginationLink': index === pageNumber}"
                            @click="pageNumber = index">
                        {{ index }}
                    </button>
                    <button type="button"
                            class="btn btn-secondary"
                            @click="pageNumber++"
                            :disabled="pageNumber === numberOfPages">
                        Next
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Spinner from "vue-simple-spinner";
    import {getPayoutBatches} from "../../services/SOAPService";
    import PayoutTableRows from "../../components/reports/PayoutBatchListItem";
    import moment from "moment";
    import moneyFormat from "../../mixins/moneyFormat";

    export default {
        name: 'PayoutReports',
        mixins: [
            moneyFormat
        ],
        components: {
            Spinner,
            PayoutTableRows
        },
        data() {
            return {
                loading: false,
                searchText: '',
                batches: [],
                pageNumber: 1,
                pageLength: 10
            };
        },
        computed: {
            validBatches() {
                if (!this.searchText) {
                    return this.batches.reverse();
                } else {
                    let searchText = this.searchText.toLowerCase();
                    // Declare a function check if input matches searchText
                    const checkMatch = (input) => String(input).toLowerCase().includes(searchText);
                    // Return only the batches that include the searchText
                    return this.batches.filter(batch => {
                        return checkMatch(this.formatDate(batch.created)) ||
                            checkMatch(this.moneyFormat(batch.amount)) ||
                            // Check if any of the batch's payouts include the searchText
                            batch.payouts.some(payout => {
                                // Format the payout's properties to user-legible formats
                                const payoutProperties = [
                                    payout.customer_bank_account.name,
                                    this.formatDate(payout.created),
                                    payout.customer_bank_account.account_number,
                                    payout.customer_bank_account.routing_number,
                                    payout.type,
                                    this.moneyFormat(payout.amount)
                                ];
                                return payoutProperties.some(checkMatch);
                            })
                    }).reverse()
                }
            },
            currentPageBatches() {
                return this.validBatches.slice(
                    (this.pageNumber - 1) * this.pageLength, this.pageNumber * this.pageLength
                );
            },
            numberOfPages() {
                return Math.ceil(this.validBatches.length / this.pageLength)
            }
        },
        mounted() {
            this.getPayouts();
        },
        watch: {
            validBatches() {
                this.pageNumber = 1;
            }
        },
        methods: {
            async getPayouts() {
                try {
                    this.loading = true;
                    const response = await getPayoutBatches();
                    this.batches = response.data;
                } catch (error) {
                    console.log(error);
                }
                this.loading = false;
            },
            formatDate(date) {
                return moment(date).format('ll');
            }
        }
    }
</script>

<style scoped>
    .activePaginationLink {
        background-color: #007bff;
        border-color: #007bff;
        color: white;
    }
</style>
